.search-container label { font-size: 16px; }
.search-box {
  -webkit-transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
  transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  border: 2px solid #e1e4ea;
  cursor: pointer;
  background: #f0b52e;
  margin-right: -85px;
  font-size: 13px;
}
.search-box + label .search-icon {
  color: black;
}
.search-box:hover {
  color: white;
  background: #f0b52e;
  box-shadow: 0 0 0 1px #e1e4ea;
}
.search-box:hover + label .search-icon {
  color: black;
}
.search-box:focus {
  -webkit-transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s, background 0.6s;
  transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s, background 0.6s;
  border: 2px solid #e1e4ea;
  outline: none;
  box-shadow: none;
  padding-left: 15px;
  cursor: text;
  width: 380px;
  border-radius: auto;
  background: #f0b52e;
  color: black;
}
.search-box:focus + label .search-icon {
  color: black;
}
.search-box:not(:focus) {
  text-indent: -5000px;
}

#search-submit {
  position: relative;
  left: -5000px;
}

.search-icon {
  position: relative;
  left: 58px;
  bottom: -2px;
  color: white;
  cursor: pointer;
}
