html { scroll-behavior: smooth; }
::-webkit-scrollbar { width: 8px; }
::-webkit-scrollbar-track { box-shadow: inset 0 0 5px #F5F5F5;  border-radius: 10px; }
::-webkit-scrollbar-thumb { background: rgba(0,0,0,0.4); border-radius: 10px; }
::-webkit-scrollbar-thumb:hover { background: rgba(0,0,0,0.6); }

@font-face {
  font-family: bangla;
  src: url(./../fonts/SolaimanLipi_20-04-07.ttf);
  font-size: 15px;
}
body { font-family: "Open Sans", sans-serif, bangla; }

@media (min-width: 576px) {
  .container { max-width: 540px; }
}
@media (min-width: 768px) {
  .container { max-width: 720px; }
}
@media (min-width: 992px) {
  .container { max-width: 992px; }
}
@media (min-width: 1200px) {
  .container { max-width: 1200px; }
}

@media (max-width: 575px) {
  main { padding-top: 65px !important; overflow: visible !important; }
}
@media (min-width: 576px) {
  main { padding-top: 60px !important; overflow: visible !important; }
}
@media (min-width: 768px) {
  main { margin-top: 170px !important; overflow: visible !important; }
}
@media (min-width: 992px) {
  main { margin-top: 70px !important; overflow: visible !important; }
}
@media (min-width: 1200px) {
  main { margin-top: 70px !important; overflow: visible !important; }
}
main { padding-top: 75px; /*background-color: #f2f2f2;*/ min-height: 86vh; padding-bottom: 40px; }

.loading { position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 1000; background-color: #e6e6e6; }
.loading-img { width: 60px; position: absolute; top: 0; bottom: 0; left: 0; right: 0; display: block; margin: auto; }
.heartbeat { -webkit-animation: heartbeat 1.5s ease-in-out 1s infinite both; animation: heartbeat 1.5s ease-in-out 1s infinite both; }
.tags { font-size: 11px; background-color: rgb(23, 43, 77); color: #fff; padding: 4px 6px; }
.division { width: 50%; display: block; margin: auto; border-bottom: 1px solid rgba(0,0,0,0.3); margin-top: 20px; margin-bottom: 20px; }
.very-small { font-size: 10px; }
.db { display: block; }
.hr { margin-bottom: 0 !important; }
.d-i { display: inline !important; }
.d-n { display: none; }
.c-p { cursor: pointer; }
.text-line-through { text-decoration: line-through !important; }
.footer { background-color: rgba(0,0,0,0.8); }
.footer .nav .nav-item .nav-link { color: #fff !important; }
.footer .nav .nav-item .nav-link:hover { color: #f0b52e !important; }
.footer .copyright { color: #fff; }
.form-group label { font-weight: 600; margin-bottom: 5px; }
.form-with-label .has-success:after, .form-with-label .has-danger:after { top: 32px; }
.iframe-modal iframe { width: 100%; height: 90vh; }
.ta-c { text-align: center; }
.badge-dark { background-color: #3c4d69; color: #fff; }
.mt--1 { margin-top: -0.25rem !important; }
.icon-qa { vertical-align: middle; }
#cartItemCount { position: absolute; margin-left: -5px; margin-top: 5px; background-color: #ff3709; color: #fff; }
.form-group .rdt { width: 100%; } /*React date time*/
.btn-share-facebook { color: #fff !important; background-color: #4267b2 !important; font-size: 0.875rem; border: 1px solid transparent !important; padding: 0.55rem 1.4rem !important; } /*Facebook Share Button*/
.btn-load-more { display: block; margin: auto; margin-top: 20px; min-width: 300px; }
.tab-content .description { font-size: 14px; }
.fw-5 { font-weight: 500 !important; }
.fw-6 { font-weight: 600 !important; }
.fw-7 { font-weight: 700 !important; }
.capitalize { text-transform: capitalize !important; }
.row .centered { float: none; margin: auto; }
.collection-header { padding: 10px; margin-bottom: 10px; border-bottom: 1px solid #f0b52e; background-color: #f0f0f0; }
.collection-header h6 { margin-bottom: 0; font-size: 14px; font-weight: 600; }
/* label .required { color: red; } */
.table-danger td { color: #f5365c; }

/*Input type arrow*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/*Input type arrow*/

/*Text-Colors*/
.color-danger { color: red !important; }
/*Text-Colors*/

/*Messenger btn*/
.fb_dialog { height: 30pt !important; width: 30pt !important; z-index: 996 !important; }
.fb_dialog svg { height: 40px !important; width: 40px !important; }
/*Messenger btn*/

/*Section Header*/
.section-header { text-align: center; margin-top: 80px; margin-bottom: 50px; line-height: 0.5; }
.section-header span { position: relative; font-size: 25px; display: inline-block; }
.section-header span:before,
.section-header span:after { content: ""; position: absolute; height: 5px; border-bottom: 1px solid #32325d; border-top: 1px solid #32325d; top: 5px; width: 50px; }
.section-header span:before { right: 100%; margin-right: 15px; }
.section-header span:after { left: 100%; margin-left: 15px; }
/*Section Header*/

                        /*Loading-Alert::Start*/
.sweet-loading { position: absolute; z-index: 990; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3); }
.sweet-loading-filter { position: relative; z-index: 999; width: 100%; height: 100%; margin-top: 30px; background-color: rgba(0,0,0,0); text-align: center; }
.swal2-popup { width: auto !important; padding: 1.25em 2.25em !important; }
.swal2-icon.swal2-warning { margin-bottom: 0.5em !important; width: 3em !important; height: 3em !important; }
.swal2-icon.swal2-success { margin-bottom: 0.5em !important; }
.swal2-icon.swal2-error { margin-bottom: 0.5em !important; }
.swal2-icon.swal2-warning::before { font-size: 2em !important; }
.swal2-title { font-size: 1em !important; }
.swal2-content { font-size: 0.8em !important; }
.swal2-actions { margin-top: 0.5em !important; }
.swal2-styled { font-size: 0.8em !important; }
.swal2-popup.swal2-toast { padding: 0.5em 0.5em 0 0.5em !important; }
                        /*Loading-Alert::End*/

        								/*Nav-Bar::Start*/
.headroom { background-color: #e1e4ea !important; /*background-color: #e6e6e6; background-image: linear-gradient(315deg, #e6e6e6 25%, #f2f2f2 100%);*/ }
.navbar-transparent .navbar-nav .nav-link { color: #212529; font-weight: 600; }
.navbar-transparent .navbar-nav .nav-link:hover, .navbar-transparent .navbar-nav .nav-link:focus { color: #f0b52e; font-weight: 600; }
.navbar-transparent .navbar-nav .nav-link .nav-link-inner--text:hover { color: #f0b52e; }
.navbar-toggler i { color: rgba(0, 0, 0, 0.5); }
.dropdown-menu .navbar-nav-hover .nav-item { width: 100%; margin-right: 0; }
.dropdown-menu .navbar-nav-hover .nav-link { padding: 8px 16px; }
.dropdown-menu .navbar-nav-hover .nav-link .nav-link-inner--text { margin-left: 0; font-size: 0.875rem; color: #212529; }
.dropdown-menu .navbar-nav-hover .nav-item .dropdown-menu { left: 100% !important; top: -20% !important; }
.dropdown-menu .navbar-nav-hover .nav-item.show .dropdown-menu.show { left: 100% !important; top: -20% !important; transform: translate3d(0px, 0px, 0px) !important; }
.dropdown-menu .navbar-nav-hover .nav-item .dropdown-menu:before { left: -9% !important; top: 8% !important; }
.dropdown-menu .navbar-nav-hover .nav-item.show .dropdown-menu.show:before { left: -9% !important; top: 8% !important; }
.nav-cart i { font-size: 20px; display: inline; }
.nav-cart .badge { font-size: 10px; padding: 4px 5px; vertical-align: top; margin-top: -10px; margin-left: -1px; background-color: #cc0000; color: #fff; }
.top-nav-fs .nav-link { font-size: 0.8rem !important; }
.nav-item.mr--20 { margin-right: -20px; }
.btn-nav { color: #212529; }
.btn-see-more .nav-link-inner--text { font-weight: 600; color: #e3a004; }

.navbar-bottom.headroom--top { top: 55px; }
.navbar-bottom.headroom { background-color: rgba(38,38,38,0.9) !important; top: 50px !important; }
.navbar-bottom .nav-link { font-size: 0.8rem !important; color: #fff !important; }
@media (max-width: 991.98px){
  .navbar-bottom .navbar-collapse.collapsing .nav-link, .navbar-bottom .navbar-collapse.show .nav-link { color: #000 !important; }
}
.side-btn-group { position: fixed; top: 30vh; left: 0; z-index: 9; }
.side-btn-group .side-btn { border-top-left-radius: 0; border-bottom-left-radius: 0; font-size: 16px; padding: 3px 5px; }
.btn-google div { margin-right: 0 !important; }
.btn-google { box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important; }
.btn-google span { color: #5e72e4 !important; font-weight: 600 !important; }
.mobile-cart-btn { display: block; position: fixed; bottom: 90px; z-index: 996; box-shadow: 0 3px 5px -1px rgba(0,0,0,.2); background: linear-gradient(180deg,#5e72e4,#2540da); border: none; color: #fff; right: 24px; width: 60px; height: 60px; border-radius: 50%; font-size: 25px; text-align: center; padding: 10px; padding-top: 10px; padding-left: 8px; font-weight: 700; }
.mobile-cart-btn:hover { color: #fff; }
.mobile-cart-btn span.badge { position: absolute; top: -8px; right: -10px; background: #f5365c; color: #fff; padding: 0.3rem 0.4rem; }
/*@media screen and (max-width: 768px){
  .mobile-cart-btn {
    display: block;
  }
}*/
												/*Nav-Bar::End*/

                        /*Home::Start*/
.home { margin: 0 50px; }
                        /*Home::End*/
                        
                        /*Banner::Start*/
.banners { width: 100%; margin-top: -30px; height: auto; }
.banners .banner-item { width: 100%; display: inline-block; }
.banners .banner-item .slider-item .preview { width: 100%; }
.banners .banner-item .slider-item .owl-carousel .item { cursor: pointer; }
.banners .banner-item .slider-item .owl-carousel .item img { width: 100%; height: 100%; max-height: 83vh; }
.banners .banner-item .slider-item .owl-carousel .owl-nav .owl-prev { transition: 0.5s; position: absolute; top: 0; left: 0; height: 100%; margin: 0; border-radius: 0; outline: none; }
.banners .banner-item .slider-item .owl-carousel .owl-nav .owl-prev span { transition: 0.5s; font-size: 50px; padding: 0px 20px 10px 15px !important; background: rgba(0,0,0,0.1); color: #fff; }
.banners .banner-item .slider-item .owl-carousel .owl-nav .owl-next { transition: 0.5s; position: absolute; top: 0; right: 0; height: 100%; margin: 0; border-radius: 0; outline: none; }
.banners .banner-item .slider-item .owl-carousel .owl-nav .owl-next span { transition: 0.5s; font-size: 50px; padding: 0px 15px 10px 20px !important; background: rgba(0,0,0,0.1); color: #fff; }
.owl-theme .owl-nav [class*='owl-']:hover { background: rgba(0,0,0,0.1) !important; }
.owl-theme .owl-nav [class*='owl-']:hover span { background: transparent !important; }
.banners .banner-item .slider-item .owl-carousel .owl-dots { position: absolute; width: 100%; bottom: 10px; }
.banners .banner-item .slider-item .owl-carousel .owl-dots .owl-dot { outline: none; }
@media screen and (max-width: 600px){
  .banners .banner-item .slider-item .owl-carousel .owl-nav .owl-prev,
  .banners .banner-item .slider-item .owl-carousel .owl-nav .owl-next { padding: 0px 10px 5px 10px !important; }
  .banners .banner-item .slider-item .owl-carousel .owl-nav .owl-prev span,
  .banners .banner-item .slider-item .owl-carousel .owl-nav .owl-next span { font-size: 25px; }
  .banners .banner-item .slider-item .owl-carousel .owl-dots { bottom: 0px; }
}
@media screen and (min-width: 769px){
  .banners .banner-col.main-banner .banner-item .slider-item .owl-carousel .item { height: 400px; }
  .banners .banner-col.side-banner .banner-item .slider-item .owl-carousel .item { height: 197px; }
  .banners .banner-col:first-child { padding-right: 3px; }
  .banners .banner-col:last-child { padding-left: 3px; }
}
@media only screen and (min-width: 601px) and (max-width: 768px){
  .banners .side-banner .inside-side-banner:first-child { padding-right: 3px; }
  .banners .side-banner .inside-side-banner:last-child { padding-left: 3px; }
}
@media only screen and (max-width: 600px){
  .banners .side-banner .inside-side-banner:first-child { padding-right: 3px; }
  .banners .side-banner .inside-side-banner:last-child { padding-left: 3px; }
}
                        /*Banner::End*/

                        /*Blood-Alert::Start*/
.blood-alert { width: 30%; height: auto; background-color: rgba(0,0,0,1);/* z-index: 2;*/ display: block; margin: auto; margin-bottom: 6px; padding: 20px 20px; }
.blood-alert .item { width: 100%; }
.blood-alert .item .media img { width: 50px; }
.blood-alert .item .media h6 { text-align: center; font-size: 13px; font-weight: 700; color: #fff; }
.blood-alert .item .media p { text-align: center; font-size: 11px; font-weight: 600; color: #fff; }
@media only screen and (min-width: 601px) and (max-width: 768px){
  .blood-alert { width: 50%; }
}
@media only screen and (max-width: 600px){
  .blood-alert { width: 80%; }
}
                        /*Blood-Alert::End*/

                        /*Custom-Order-Card::Start*/
.custom-order-cards { margin-top: 24px; }
.custom-order-cards .custom-order-card { width: 100%; display: inline-block; margin: auto; z-index: 1; }
.custom-order-cards .custom-order-card img { width: 120px; height: 120px; border-radius: 50%; display: block; margin: auto; transition: 0.5s; box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); object-fit: cover; }
.custom-order-cards .custom-order-card:hover img { -ms-transform: scale(1.1); -moz-transform: scale(1.1); -webkit-transform: scale(1.1); -o-transform: scale(1.1); transform: scale(1.1); }
.custom-order-cards .custom-order-card .overlay { position: relative; width: 100%; height: auto; padding: 0; margin-top: 12px; background-color: transparent; text-align: center; font-size: 17px; }
.custom-order-cards .custom-order-card .overlay span { color: #000; margin-bottom: 0; font-weight: 700; }
.custom-order-cards .custom-order-card:hover .overlay span { color: #f0b52e; }
@media only screen and (min-width: 601px) and (max-width: 768px){
  .custom-order-cards .custom-order-card { width: 100%; }
}
@media only screen and (max-width: 600px){
  .custom-order-cards .custom-order-card { width: 100%; }
}
                        /*Custom-Order-Card::Start*/

                        /*Best-Selling-Card::Start*/
.best-selling-cards { margin-top: 20px; }
/* @media screen and (min-width: 769px){
  .best-selling-cards .best-selling-col { padding: 0 5px; }
  .best-selling-cards .best-selling-col:first-child { padding-left: 15px; }
  .best-selling-cards .best-selling-col:last-child { padding-right: 15px; }
}
@media only screen and (min-width: 601px) and (max-width: 768px){
  .best-selling-cards .best-selling-col { padding: 0 5px; }
  .best-selling-cards .best-selling-col:nth-child(odd) { padding-left: 15px; }
  .best-selling-cards .best-selling-col:nth-child(even) { padding-right: 15px; }
} */
.best-selling-cards .best-selling-card { width: 100%; padding: 10px; margin-bottom: 10px; border-radius: 10px; background-color: rgba(225, 228, 234,1); box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
.best-selling-cards .best-selling-card.best-selling-products { background: #4286f4;  /* fallback for old browsers */ background: -webkit-linear-gradient(to top, #4286f4, #373B44);  /* Chrome 10-25, Safari 5.1-6 */ background: linear-gradient(to top, #4286f4, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
.best-selling-cards .best-selling-card.best-authors { background: #52c234;  /* fallback for old browsers */ background: -webkit-linear-gradient(to bottom, #373B44, #52c234);  /* Chrome 10-25, Safari 5.1-6 */ background: linear-gradient(to bottom, #373B44, #52c234); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
.best-selling-cards .best-selling-card.best-publishers { background: #e52d27;  /* fallback for old browsers */ background: -webkit-linear-gradient(to bottom, #373B44, #e52d27);  /* Chrome 10-25, Safari 5.1-6 */ background: linear-gradient(to bottom, #373B44, #e52d27); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
.best-selling-cards .best-selling-card.best-users { background: #f0b52e;  /* fallback for old browsers */ background: -webkit-linear-gradient(to bottom, #373B44, #f0b52e);  /* Chrome 10-25, Safari 5.1-6 */ background: linear-gradient(to bottom, #373B44, #f0b52e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }
.best-selling-cards .best-selling-card .header { text-align: center; font-size: 15px; font-weight: 600; color: #fff; }
.best-selling-cards .best-selling-card .form-control { font-size: 11px; height: auto; padding: 5px; margin: auto; border-radius: 5px; }
.best-selling-cards .best-selling-card .item-lists { margin-top: 10px; background-color: #fff; padding: 10px; height: auto; overflow-x: scroll; border-radius: 5px; }
/* @media screen and (min-width: 769px){
  .best-selling-cards .best-selling-card .item-lists { height: 400px; }
  .best-selling-cards .best-selling-card.best-publishers .item-lists, .best-selling-cards .best-selling-card.best-users .item-lists { height: 436px; }
} */
.best-selling-cards .best-selling-card .item-lists .items .media { border-bottom: 1px solid #e6e6e6; padding: 10px 0; color: #333333; }
.best-selling-cards .best-selling-card .item-lists .items a:first-child .media { padding-top: 0; }
/* .best-selling-cards .best-selling-card .item-lists .items a:last-child .media { border-bottom: none; padding-bottom: 0; } */
.best-selling-cards .best-selling-card .item-lists .items .media:hover { color: #f0b52e; }
.best-selling-cards .best-selling-card .item-lists .items .media img { width: 30px; height: auto; }
.best-selling-cards .best-selling-card.best-authors .item-lists .items .media img { width: 35px; height: 35px; border-radius: 50%; }
.best-selling-cards .best-selling-card .item-lists .items .media .headline { font-size: 12px; font-weight: 600; width: 100%; display: block; }
.best-selling-cards .best-selling-card .item-lists .items .media .headline .price { display: inline-block; float: right; }
.best-selling-cards .best-selling-card .item-lists .items .media .headline .price img { width: 25px; }
.best-selling-cards .best-selling-card .item-lists .items .media .headline .discount-price { color: #F8463F; }
.best-selling-cards .best-selling-card .item-lists .items .media .subtitle { font-size: 11px; display: block; }
.best-selling-cards .best-selling-card .item-lists .best-selling-see-more { width: 100%; text-align: center; padding-top: 10px; }
/* .best-selling-cards .best-selling-card .item-lists .best-selling-see-more a { font-size: 13px; font-weight: 600; text-decoration: none; color: #333333; } */
/* .best-selling-cards .best-selling-card .item-lists .best-selling-see-more a:hover { color: #f0b52e; } */

.best-selling-cards .best-selling-card.best-publishers .items .media { border-bottom: none; margin-bottom: 10px; background-color: #f2f2f2; box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15); border-radius: 10px; padding-bottom: 15px; padding-top: 5; }
.best-selling-cards .best-selling-card.best-publishers .item-lists .items a:last-child .media { padding-bottom: 15px; }
.best-selling-cards .best-selling-card.best-publishers .items .media .headline { font-size: 15px; }
.best-selling-cards .best-selling-card.best-publishers .items .media .subtitle { font-size: 13px; }
                        /*Best-Selling-Card::End*/

                        /*Collections::Start*/
.collections .collection { margin-top: 30px; }
.collections .collection .collection-header { padding: 10px; margin-bottom: 10px; border-bottom: 1px solid #f0b52e; background-color: #f0f0f0; }
.collections .collection .collection-header h6 { margin-bottom: 0; font-size: 14px; font-weight: 600; }
.collections .collection .item:first-child { margin-left: 2px; }
.collections .collection .feature-value { text-align: center; }
.collections .collection .feature-value img { width: 100px; height: 100px; border-radius: 50%; display: block; margin: auto; transition: 0.5s; box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); margin-bottom: 10px; }
.collections .collection .feature-value span { font-size: 13px; font-weight: 700; color: #525f7f; }
.collections .collection .feature-value:hover span { color: #f0b52e; }
.collections .collection .feature-value.see-more { width: 100px; height: 100px; background-color: #f0f0f0; border-radius: 50%; padding-top: 36px; box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
.collections .collection .owl-carousel .owl-nav .owl-prev { position: absolute; top: 35%; left: 0; font-size: 35px; background-color: rgba(0,0,0,0.2) !important; padding: 0px 15px 5px 15px !important; outline: none; margin: 0; color: #fff; border-radius: 0; }
.collections .collection .owl-carousel .owl-nav .owl-next { position: absolute; top: 35%; right: 0; font-size: 35px; background-color: rgba(0,0,0,0.2) !important; padding: 0px 15px 5px 15px !important; outline: none; margin: 0; color: #fff; border-radius: 0; }
.collections .collection .owl-carousel .owl-nav .owl-prev:hover,
.collections .collection .owl-carousel .owl-nav .owl-next:hover { background-color: rgba(0,0,0,0.3) !important; }
                        /*Collections::End*/

                        /*Category::Start*/
.product-hierarchy { margin-bottom: 25px; }
.product-hierarchy a { font-size: 15px; font-weight: 700; color: #525f7f; }
.product-hierarchy a:hover { color: #f0b52e; }
.product-hierarchy .fa-arrow-right { margin: 0 10px; font-weight: 400; }
.categories { margin-bottom: 25px; /*text-align: center;*/ }
.categories .owl-dots { display: none; }
.categories a { text-decoration: none; color: #525f7f; font-size: 15px; font-weight: 600; }
.categories a:hover .header h6 { color: #f0b52e; }
.categories .category-folder-col { padding-right: 0; }
@media screen and (min-width: 768px){
  .categories .category-folder-col { flex: 0 0 20%; max-width: 20%; }
}
@media screen and (min-width: 1024px){
  /* .categories .category-folder-col:not(:nth-child(8n)) { padding-right: 15px; } */
  /* .categories .category-folder-col:not(:nth-child(6n)) { padding-right: 0; } */
  .categories .category-folder-col { flex: 0 0 16.3333%; max-width: 16.3333%; }
}
@media screen and (min-width: 1200px){
  /* .categories .category-folder-col::nth-child(8n) { padding-right: 15px; } */
  /* .categories .category-folder-col:not(:nth-child(6n)) { padding-right: 0; } */
  .categories .category-folder-col { flex: 0 0 12.5%; max-width: 12.5%; }
}
.cards .collection-header { margin-left: -6px; }
.category-folder { width: 100%; padding: 10px 20px 10px 20px; margin-bottom: 10px; background: #ECE9E6;  /* fallback for old browsers */ background: -webkit-linear-gradient(to bottom, #FFFFFF, #ECE9E6);  /* Chrome 10-25, Safari 5.1-6 */ background: linear-gradient(to bottom, #FFFFFF, #ECE9E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ box-shadow: 0 2px 7px #dfdfdf; text-align: center; border-radius: 10px; }
.category-folder .thumb { height: 100px; margin-bottom: 10px; overflow: hidden; }
.category-folder .thumb img { width: 100%; height: auto; background-size: cover; }
.category-folder .header { height: 50px; overflow: hidden; }
.category-folder .header h6 { color: #525f7f; font-size: 12px; font-weight: 600; }
                        /*Category::End*/

												/*Product-Card::Start*/
.products { margin: 0 50px; position: relative; }
.products.related { margin: 0; }
.products .products-heading { width: 100%; border: 1px solid rgba(0,0,0,0.5); background: #fff; text-align: center; padding: 10px; margin-bottom: 0; font-size: 15px; font-weight: 700; box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
.products .products-shipping-cost { padding: 15px 20px 5px 20px; background: #fff; box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
.products .products-shipping-cost .location { font-size: 13px; font-weight: 600; display: block; }
.products .products-shipping-cost .charge { font-size: 13px; font-weight: 600; display: block; }
.products .products-shipping-cost .charge .amount { float: right; }
/*.products .cards { width: 100%; display: flex; display: -webkit-flex; justify-content: center; -webkit-justify-content: center; margin-bottom: 20px; }*/
.products .cards .col-lg-2 { padding-left: 10px; padding-right: 10px; }
.product-card { width: 100%; position: relative; box-shadow: 0 2px 7px #dfdfdf; margin: auto; margin-bottom: 20px; background: #fafafa; }
.product-card .badge { position: absolute; left: -1px; top: 20px; text-transform: uppercase; font-size: 11px; font-weight: 700; background: red; color: #fff; padding: 3px 10px; border-top-left-radius : 0; border-bottom-left-radius: 0; }
.product-card .product-tumb { display: flex; align-items: center; justify-content: center; width: 100%; height: 190px; padding: 0; background: #f0f0f0; padding: 10px; }
.product-card .product-tumb img { max-width: 100%; width: 100%; height: 170px; object-fit: contain; }
.product-card .product-details { padding: 10px; height: 130px; }
.product-card .product-catagory { display: block; font-size: 8px; font-weight: 600; text-transform: uppercase; color: #ccc; }
.product-card .product-details h4 { font-weight: 600; font-size: 13px; display: block; margin-bottom: 0; color: #363636; text-decoration: none; transition: 0.3s; }
.product-card-link:hover .product-card .product-details h4 { color: #f0b52e; }
.product-card .product-top-details { overflow: hidden; max-height: 67px; margin-bottom: 5px; }
.product-card .product-top-details h4 { max-height: 39px; overflow: hidden; }
.product-card .product-top-details p { font-size: 11px; color: #363636; margin: 0; line-height: 1.1; font-weight: 600; margin-top: 3px; margin-bottom: 2px; }
.product-card-link:hover .product-card .product-top-details p { color: #f0b52e; }
.product-card .product-category { font-size: 7px; font-weight: 500; text-transform: uppercase; color: #fff; letter-spacing: 1px; background-color: rgba(0,0,0,0.5); padding: 1px 5px; border-radius: 4px; }
.product-card .product-bottom-details { overflow: hidden; /*height: 60px;*/ border-top: 1px solid #eee; position: absolute; bottom: 0; height: auto; width: 85%; }
.product-card .product-price { font-size: 13px; color: #f0b52e; font-weight: 600; float: left; width: 100%; }
.product-card .product-price.has-discount { font-size: 11px !important; }
.product-card .product-price.discount-price { font-size: 13px; color: #F8463F; }
.product-card .product-price small { font-size: 80%; font-weight: 400; text-decoration: line-through; display: inline-block; margin-right: 5px; }
.product-card .product-links { text-align: right; }
.product-card .product-links .wish-btn { display: inline-block; margin-left: 5px; color: #e1e1e1; transition: 0.3s; font-size: 13px; vertical-align: text-top; }
.product-card .product-links .wish-btn:hover { color: #f0b52e; }
.product-card .product-discount { z-index: 9; padding: 4px 5px; position: absolute; top: -10px; right: -10px; width: auto; -webkit-border-radius:0 4px 0 4px; -moz-border-radius:0 4px 0 4px; border-radius:0 4px 4px 0; background-color: #e60000; -ms-transform: rotate(-45deg); -webkit-transform: rotate(-45deg); transform: rotate(-45deg); }
.product-card .product-discount:after { right: 100%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute;border-color: rgba(136, 183, 213, 0); border-width: 16px; top: 50%; margin-top: -16px; border-right-color: #e60000;}
.product-card .product-discount:before { content: ''; z-index: 2; position: absolute; top: 42%; right: 100%; width: 7px; height: 7px; opacity: .95; background: #ffffff; border-radius: 7px; -webkit-box-shadow: inset .5px 0 rgba(0, 0, 0, 0.6); box-shadow: inset .5px 0 rgba(0, 0, 0, 0.6);}
.product-card .product-discount span { font-size: 12px; font-weight: 600; color: #fff; }
.product-card .overlay { position: absolute; width: 100%; top: 0; left: 0; bottom: 0; background-color: rgba(0,0,0,0.3); z-index: 99; }
.product-card .overlay span { position: absolute; top: 45%; width: 100%; background-color: #f0b52e; color: #fff; font-size: 12px; padding: 10px; text-align: center; font-weight: 700; }
.product-card .ribbon4 { background: #F8463F; color: #fff; font-size: 12px; font-weight: 700; padding: 4px 6px; top: 5px; }
.product-card .ribbon4:before { background: #F8463F; height: 130%; }
.product-card .ribbon4:after { background: #C02031; border-radius: 3px 0 0 6px; }
.product-card:hover { transition: 0.2s ease-in; -webkit-transform: translateY(-5px); transform: translateY(-5px); box-shadow: 0px 45px 60px 0px rgba(0, 0, 0, 0.087); }
/*.product-card { position: relative; padding: 10px; background: white; display: grid; grid-template-rows: 8fr 1fr 1fr; box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.057); transition: 0.2s ease-in; }
.product-card .card-content { display: flex; justify-content: center; align-items: center; width: 100%; }
.product-card .card-content .top-bar { width: calc(100% - 40px); position: absolute; top: 0; left: 0; padding: 20px; }
.product-card .card-content .top-bar .float-right { float: right; }
.product-card .card-content .img { width: 100%; justify-content: bottom; align-content: bottom; text-align: center; }
.product-card .card-content .img img { max-width: 100%; max-height: 220px; }
.product-card .card-description { padding: 10px; display: flex; justify-content: space-between; align-items: center; font-weight: 400; font-size: 14px; letter-spacing: 0.1em; width: calc(100%-20px); }
.product-card .card-description .title { text-transform: uppercase; text-align: left; }
.product-card .card-description .cart { float: right; cursor: pointer; }
.product-card .card-footer { text-transform: uppercase; padding: 0.3em; border-top: 0.5px solid #ececec; letter-spacing: 0.1em; font-size: 11px; color: white; justify-items: left; align-items: center; display: flex; }
.product-card .card-footer .span { margin: 5px; width: auto; background: #dedede; padding: 4px 7px; border-radius: 2px; font-weight: 100; cursor: pointer; }
.product-card .card-footer .span:hover { background: #aaaaaa; }
.product-card:hover { transition: 0.2s ease-in; -webkit-transform: translateY(-10px); transform: translateY(-10px); box-shadow: 0px 45px 60px 0px rgba(0, 0, 0, 0.087); }*/
@media screen and (max-width:600px){
  .products { margin: 0 20px; }
}

/*Product Card Loading*/
.product-loading { width: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; box-shadow: 0 2px 7px #dfdfdf; margin: auto; background: #fafafa; height: 320px; }
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.product-loading {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #F6F6F6;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #F6F6F6), color-stop(18%, #F0F0F0), color-stop(33%, #F6F6F6));
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
}
/*Product Card Loading*/
.products .author-col { padding-left: 10px; }
.products .media.author-media { padding: 20px; background: #fff; box-shadow: 0 2px 7px #dfdfdf; margin-bottom: 15px; }
.products .media.author-media img { width: 60px; height: 60px; border-radius: 50%; border: 2px solid #808080; }
.products .media.author-media h6 { font-size: 14px; font-weight: 600; }
.products .media.author-media p { font-size: 13px; }

                        /*Product-Card::End*/

                        /*Feature-Value-Card::Start*/
.feature-value-cards .feature-value { text-align: center; margin-bottom: 20px; }
.feature-value-cards .feature-value img { width: 100px; height: 100px; border-radius: 50%; display: block; margin: auto; margin-bottom: 10px; transition: 0.5s; box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
.feature-value-cards .feature-value span { font-size: 13px; font-weight: 700; color: #525f7f; }
.feature-value-cards .feature-value:hover span { color: #f0b52e; }

                        /*Feature-Value-Card::End*/

                        /*User-Product-Card::Start*/
.product-card.user-product-card .product-details { height: 125px; padding-top: 0; }
.product-card.user-product-card .product-details .product-top-details { max-height: 100px; }
.product-card.user-product-card .product-details .product-top-details .user-product-location { font-size: 10px; text-decoration: none; color: #363636; margin-bottom: 10px; margin-top: -5px; }
.product-card.user-product-card .product-details .product-top-details .user-product-location span { font-weight: 600; }
.user-product-description { border-top: 1px solid rgba(0,0,0,0.4); margin-top: 10px; padding-top: 20px; }
.user-product-description h6 { font-size: 14px; font-weight: 600; }
.user-product-description p { font-size: 15px; font-weight: 500; }
.user-product-location { font-size: 14px; }
.user-product-location .fa-map-marker { font-size: 13px; }
.user-product-location span { font-weight: 500; }
.product-card.user-product-card .ribbon1 { /*background: #F8463F;*/ color: #fff; font-size: 12px; font-weight: 700; right: 5px; border-top-right-radius: 5px; }
.product-card.user-product-card .ribbon1:after { border-left: 45px solid transparent; border-left: 45px solid transparent; border-right: 45px solid transparent; border-right: 45px solid transparent; }
.product-card.user-product-card .ribbon1 span { padding: 8px; }
/*.user-product-card .has-sold-out { position: absolute; top: 20px  ; right: 0; width: auto; margin: 0; border-radius: 0; }*/
                        /*User-Product-Card::End*/

                        /*Filter::Start*/
.filter { margin-bottom: 70px; }
.filter h5 { border: 1px solid rgba(0,0,0,0.5); text-align: center; font-weight: 700; font-size: 16px; padding: 6px 0; }
.filter label { font-size: 13px; font-weight: 600; }
.filter .form-control { font-size: 12px; padding: 5px 6px; height: 40px; }
.filter .price { padding-left: 5px; padding-right: 5px; }
.filter .price .range-slider-value { font-size: 10px; }
.filter .price .slider { margin: 0; margin-bottom: 10px; }
.filter .custom-checkbox .custom-control-label { font-size: 13px; font-weight: 500; }
.filter .custom-checkbox .custom-control-label::before, .filter .custom-checkbox .custom-control-label::after { width: 1rem; height: 1rem; top: 0.15rem; }
.filter .btn-sm { padding: 6px 8px; font-size: 11px; }
.filter .feature-item { max-height: 150px; overflow-y: scroll; }
/* .filter .see-more { border-top: 5px solid rgba(242, 242, 242, 0.5); } */
.filter .see-more button { display: block; margin: auto; width: 100%; background: transparent; border: none; font-size: 10px; cursor: pointer; border: 1px solid #808080; }
.filter .see-more button:focus { outline: none; }
.products .products-heading-mobile { display: none; position: fixed; bottom: 117px; z-index: 996; box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2); background: linear-gradient(to bottom, #f0b52e, #bf890d); border: none; color: #fff; right: 25px; width: 40px; height: 40px; border-radius: 50%; padding-top: 5px; font-size: 22px; margin-bottom: 0; }
@media screen and (max-width:768px){
  /* .filter { margin: 0px; display: block; position: fixed; z-index: 995; top: 50px; background: #e6e6e6; padding: 20px; border: 0; left: 0; right: 0; bottom: 0; overflow-y: scroll; } */
  .filter { display: none; }
  /* .filter .products-heading { display: none; } */
  /* .products .products-heading-mobile { display: block; } */
  /* .filter .filter-reset { position: fixed; bottom: 120px; right: 65px; background-color: #f5365c; color: #fff; box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);  } */
  /* .filter .filter-apply { position: fixed; bottom: 120px; right: 130px; background-color: #5e72e4; color: #fff; box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);  } */
}
                        /*Filter::End*/

												/*Product-Gallery::Start*/
.fa-cart-plus { font-size: 18px; }
.product .gallery { width: 100%; }
.product .gallery .preview { width: 100%; }
.product .gallery .enlarged-preview { z-index: 1; background-color: #fff; }
.product .gallery .owl-carousel { margin-top: 10px; }
.product .gallery .owl-carousel .item { cursor: pointer; }
.product .shop h3 { margin-bottom: 0; }
.product .shop h3 small { font-size: 60%; vertical-align: middle; padding-right: 10px; }
.product .shop .fa.star-rating { color: #f0b52e; }
.product .shop .table { width: auto; margin-top: 15px; }
.product .shop .table td { width: auto; font-size: 13spx; font-weight: 600; }
.product .shop .table .form-group { margin-bottom: 10px; width: 170px; }
.product .shop .table .form-group .input-group { width: 120px; }
.product .shop .table .form-group .form-control { padding: 6px 0; }
.product .shop .table .form-group .input-group-prepend { cursor: pointer; }
.product .shop .table .form-group .input-group-prepend .input-group-text { border-right: 0; padding: 0; }
.product .shop .table .form-group .input-group-prepend .input-group-text i { padding: 6px 12px; }
.product .shop .table .form-group .form-control { height: auto; text-align: center; width: 10px; }
.product .shop .table .form-group .form-control[readonly] { background-color: #fff; }
.product .shop .table .form-group .input-group-append { cursor: pointer; }
.product .shop .table .form-group .input-group-append .input-group-text { border-left: 0; padding: 0; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; }
.product .shop .table .form-group .input-group-append .input-group-text i { padding: 6px 12px; }
.product .shop .table .form-group.is-invalid .form-control { border-color: #fb6340; }
.product .shop .table .form-group.is-invalid .input-group-text { border-color: #fb6340; }
.product .shop .buttons { margin-top: 30px; }
.product .shop .subname { font-size: 14px; margin-right: 20px; }
.product .shop .featured-features span { font-size: 14px; }
.product .shop .featured-features span a { color: #525f7f; }
.product .shop .featured-features span a:hover { color: #f0b52e; }
.product .shop .tags { margin-right: 3px; font-size: 10px; }
.product .shop .discount-price { color: #F8463F; }
.product .shop .ribbon3.discount { background-color: #F8463F; font-size:12px; text-align:center; position:relative; margin:auto; padding: 0 10px; color:#fff; font-weight: 700; width: auto; height: auto; line-height: 25px; display: inline-block; top: 0; left: 0; float: right; margin-top: 10px; }
.product .shop .ribbon3.discount:before, .product .shop .ribbon3.discount:after { background-color:#a44; width:30px; height:20px; content:''; display:block;  transform:skewY(20deg); position:absolute; z-index:-1; }
.product .shop .ribbon3.discount:before { top:10px; left:0; border: none; }
.product .shop .ribbon3.discount:after { top:-6px; right:0; border: none; }
/*.product .shop .discount { display: inline-block; margin-left: 20px; }
.product .shop .discount h1 { background-color:#F8463F; font-size:12px; text-align:center; position:relative; margin:auto; color:#fff; font-weight: 700; width: 100px; height: 25px; line-height: 25px; display: inline-block; }
.product .shop .discount h1:before, .product .shop .discount h1:after { background-color:#a44;  width:30px; height:20px; content:''; display:block;  transform:skewY(20deg); position:absolute; z-index:-1; }
.product .shop .discount h1:before {top:10px; left:0; }
.product .shop .discount h1:after {top:-6px; right:0; }*/
@media screen and (max-width:600px){
  .product .gallery .preview { padding: 0 50px; }
}
												/*Product-Gallery::Start*/

												/*Product-Details::Start*/
.details { margin-top: 20px; }
.details .description { overflow-x: scroll; }
.details .feature-table { font-size: 14px; margin-top: 10px; border-spacing: 60px; }
.details .feature-table tr a { color: #525f7f; }
.details .feature-table tr a:hover { color: #f0b52e; }
.details .feature-table th { vertical-align: top; }
.details .feature-table td span { display: block; }
												/*Product-Details::End*/

                        /*News-Card::Start*/
.news-card { width: 100%; margin-bottom: 1rem; position: relative; background-color: #fff; box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15); transition: all 0.25s ease-in; border-bottom: 5px solid transparent; }
.news-card:hover { transform: translateY(-5px); border: none; border-bottom: 5px solid #f0b52e; }
.news-card .news-thumb { align-items: center; justify-content: center; width: 100%; height: 180px; padding: 0; background: #f0f0f0; padding: 10px; }
.news-card .news-thumb img { max-width: 100%; width: 100%; height: 100%; object-fit: contain; }
.news-card .news-card-body { padding: 1rem; padding-bottom: 0rem; }
.news-card .news-card-body .news-topic { height: 20px; overflow: hidden; }
.news-card .news-card-body .news-topic .tags { font-size: 9px; margin-right: 3px; margin-bottom: 5px; }
.news-card .news-card-body h1 { font-weight: 600; font-size: 0.9rem; margin-top: 0; margin-bottom: 2px; color: rgba(0,0,0,0.8); height: 20px; overflow: hidden; }
.news-card .news-card-body p { margin-bottom: 0; font-size: 11px; color: rgba(0,0,0,0.8); font-weight: 500; }
.news-card .news-card-body .news-topic { margin-bottom: 0; font-weight: 600; font-size: 0.5rem; text-transform: uppercase; color: rgba(0, 0, 0, 0.5); letter-spacing: 2px; }
.news-card .news-card-body .news-topic.event-date { height: 20px; overflow: hidden; font-size: 10px; }
.news-card .read-more { text-transform: uppercase; display: inline-block; font-weight: 500; letter-spacing: 2px; font-size: 0.6rem; padding-left: 1rem; margin-bottom: 1rem; text-align: center; text-decoration: none; outline: none; color: #f0b52e; transition: all 0.25s ease-in; }
.news-card.news-empty-card { width: 100%; height: 337px; background-color: #f0f0f0; }
.news-card.news-empty-card:hover { transform: translateY(0px); border-bottom: 5px solid transparent; }
.news-card.news-empty-card h1 { font-size: 15px; font-weight: 600; text-align: center; padding-top: 150px; }
.news-card.news-empty-card.event-empty-card { height: 270px; }
.news-card.news-empty-card.event-empty-card h1 { padding-top: 120px; }
                        /*News-Card::End*/

                        /*News-Details::Start*/
.product .gallery.news-gallery .owl-carousel .item { cursor: default; }
.news { width: 100%; margin-top: 1rem; }
.news .news-topic { margin-bottom: 0; font-weight: 600; font-size: 0.6rem; text-transform: uppercase; color: rgba(0, 0, 0, 0.7); letter-spacing: 1px; }
.news .news-title { margin-bottom: 0; font-weight: 600; font-size: 1.4rem; color: rgba(0,0,0,0.9); line-height: 1.2; }
.news .news-read { margin-bottom: 0; font-size: .9rem; color: rgba(0,0,0,0.8); font-weight: 500; }
                        /*News-Details::End*/

                        /*Event-Details::Start*/
.news-card.event-card:hover { transform: translateY(0px); border-bottom: 5px solid transparent; box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
.news-card .news-topic.event-date { font-size: 11px; letter-spacing: 0px; }
.news-card .event-card-body { margin-bottom: 0px; padding: 0.5rem 1rem; }
.news-card .event-card-body h1 { margin-bottom: 0; }
.news-card .event-card-body .news-topic.event-date { height: 15px; }
.news-card .event-card-body .fa-map-marker { font-size: 12px; padding: 0 1px; }
                        /*Event-Details::End*/


                        /*Commments::Start*/
/* .comments {  } */
.comments .media { margin-top: 20px; }
.comments .media-object { width: 30px; height: 30px; border-radius: 50%; vertical-align: top; }
.comments .media-body { margin-left: 10px; font-size: 13px; }
.comments .media-heading { font-size: 14px; font-weight: 700; }
.comments .star-rating { color: #f0b52e; }
.comments .rating-div { width: 110px !important; }
.comments .rating-div .post-rating { color: #4d4d4d; cursor: pointer; }
.comments .rating-div .post-rating.checked { color: #f0b52e !important; }
.comments .rating-div:hover .post-rating { color: #f0b52e; }
.comments .rating-div .post-rating:hover ~ .post-rating { color: #4d4d4d; }
.comment-span { font-size: 14px; font-weight: 500; }
/*.comments .actions { display: block; margin-left: 10px; }*/
.comments .reply { display: none; }
.comments .actions a { color: #737373; font-size: 10px; font-weight: 700; text-decoration: underline; }
.comments .comment.reply-form .media-body { margin-left: 0; }
.comment-images { margin: 10px 0; }
.comment-image { width: 100px; }
                        /*Commments::End*/

                        /*Cart::Start*/
.cart table { color: #525f7f; background-color: transparent; border-spacing: 2px; border-color: grey; }
.cart table th { padding-right: 1rem; padding-left: 1rem; color: #8898aa; background-color: #f6f9fc; font-size: .65rem; padding-top: .75rem; padding-bottom: .75rem; letter-spacing: 1px; text-transform: uppercase; border-bottom: 1px solid #e9ecef; border-right: 0; border-left: 0; white-space: nowrap; font-weight: 600; border-top: 1px solid #e9ecef; text-align: center; }
.cart table tbody tr:first-child td { border-top: 0; }
.cart table td { text-align: center; padding: 1rem; padding-right: 1rem; padding-left: 1rem; vertical-align: middle; border-right: 0; border-left: 0; font-size: .8125rem; white-space: nowrap; border-top: 1px solid #e9ecef; }
.cart .table-user { text-align: left; }
.cart .table-user a { color: #525f7f; }
.cart .table-user a:hover { color: #f0b52e; }
.cart .table-user .avatar { display: inline-flex; width: 48px; height: 48px; color: #fff; border-radius: .375rem; background-color: #adb5bd; align-items: center; justify-content: center; margin-right: 1rem!important; border-radius: 50%!important; }
.cart span.br { display: block; }
@media only screen and (min-width: 700px){ .cart .cart-items { overflow-x: auto; } }
.cart .cart-items table { width: 100%; }
.cart .cart-items table td { white-space: normal; }
.cart .cart-items .form-group { width: 120px; display: block; margin: auto; }
.cart .cart-items .form-group .input-group-text, .cart .cart-items .form-group .form-control { padding: 0.25rem 0.75rem; font-size: 10px; line-height: 0.5; }
.cart .cart-items .form-group .form-control { font-size: 14px; text-align: center; }
.cart .cart-items .input-group-text { cursor: pointer; }
.cart th.total { font-size: .7rem; }
.cart .text-line-through { font-size: 11px; font-weight: 600; }
.cart .discount-price { color: #F8463F; padding-left: 8px; font-weight: 600; }
/*.cart .cart-delivery .form-control { width: 100%; display: inline-block; margin-right: 5px; }*/
/*.cart .cart-delivery #address {  }*/
/*.cart .status-image { width: 500px; background-color: #fff; display: block; margin: auto; box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }*/
/*.cart .status-image img { width: 50px; }*/
/*.cart .status-image h6 { padding: 0 20px 20px 20px; font-weight: 600; }*/
.cart .status-images-div { margin: 20px auto; }
.cart .status-images-div ul.status-images { text-align: center; }
.cart .status-images-div ul.status-images li { display: inline-block; width: 150px; position: relative; z-index: 1; }
.cart .status-images-div ul.status-images li .fa { font-size: 13px; padding: 5px; border-radius: 50%; background-color: #ccc; color: #fff; z-index: 2; }
.cart .status-images-div ul.status-images li .fa::after { content: ''; background: #ccc; height: 4px; width: 150px; display: block; position: absolute; left: 0; top: 66px; z-index: -1; }
.cart .status-images-div ul.status-images li:first-child .fa::after { width: 75px; left: 75px; }
.cart .status-images-div ul.status-images li:last-child .fa::after { width: 75px; }
.cart .status-images-div ul.status-images li .fa.done { background-color: green; }
.cart .status-images-div ul.status-images li .fa.done::after { background: green; }
.cart .status-images-div ul.status-images li .fa.running { background-color: #f0b52e; padding: 5px 6px; }
.cart .status-images-div ul.status-images li .fa.running::after { background: #f0b52e; }
.cart .status-images-div ul.status-images li .fa.cancel { background-color: red; padding: 5px 6px; }
.cart .status-images-div ul.status-images li .fa.cancel::after { display: none; }
.cart .status-images-div ul.status-images img { width: 50px; margin-bottom: 5px; }
.cart .status-images-div p { font-size: 12px; font-weight: 600; margin-top: 3px; }
.cart .order-details span { display: block; font-size: 12px; }
.cart .cart-delivery .form-group { margin-bottom: 5px; }
.cart .cart-delivery label { font-size: 12px; }
.cart .cart-delivery .form-control { font-size: 12px; height: auto; padding: 8px 10px; }
.cart .cart-delivery .invalid-feedback { font-size: 12px; }
/* .input-group.input-group-store-credit { width: 350px; } */
.input-group.input-group-store-credit .input-group-text { background-color: #fbb104; color: #fff; font-size: 12px; font-weight: 600; padding: 8px 10px; border-radius: 0; border: none; }
.input-group.input-group-store-credit .input-group-append .input-group-text { background-color: transparent; }
.input-group.input-group-store-credit .form-control { font-size: 12px; font-weight: 600; padding: 8px 10px; border-radius: 0; height: auto; }
.input-group.input-group-store-credit .btn-icon.btn-success { padding: 6px 10px; border-radius: 0; }
.input-group.input-group-store-credit .btn-icon.btn-danger { padding: 6px 11px; border-radius: 0; }
/* .input-group.input-group-coupon { width: 350px; } */
.input-group.input-group-coupon .input-group-text { background-color: #5e72e4; color: #fff; font-size: 12px; font-weight: 600; padding: 8px 10px; border-radius: 0; border: none; }
.input-group.input-group-coupon .input-group-append .input-group-text { background-color: transparent; }
.input-group.input-group-coupon .form-control { font-size: 12px; font-weight: 600; padding: 8px 10px; border-radius: 0; height: auto; }
.input-group.input-group-coupon .btn-icon.btn-success { padding: 6px 10px; border-radius: 0; }
.input-group.input-group-coupon .btn-icon.btn-danger { padding: 6px 11px; border-radius: 0; }
.cart-summary table tr { border-left: 1px solid #e9ecef; border-right: 1px solid #e9ecef; }
.charge-input { height: 20px; width: 20px; }
                        /*Cart::End*/

                        /*Stepper::Start*/
.stepper ul { text-align: center; margin-bottom: 40px; }
.stepper ul li { display: inline-block; width: 200px; cursor: pointer; position: relative; }
.stepper ul li p { font-size: 15px; font-weight: 600; padding: 0; margin: 0; margin-top: 10px; }
.stepper ul li .fa { background: #ccc; width: 50px; height: 50px; color: #fff; border-radius: 50%; padding: 15px; font-size: 20px; }
.stepper ul li .fa::after { content: ''; background: #ccc; height: 5px; width: 205px; display: block; position: absolute; left: 0; top: 25px; z-index: -1; }
.stepper ul li:first-child .fa::after { width: 105px; left: 100px; }
.stepper ul li:last-child .fa::after { width: 105px; left: 0; }
.stepper ul li.active .fa, .stepper ul li.active .fa::after { background: #f0b52e; }
.stepper ul li.complete .fa, .stepper ul li.complete .fa::after { background: #2dce89; }
.step-item { display: none; }
                        /*Stepper::End*/

                        /*Pre Order::Start*/
.profile-card .card-body.pre-order { text-align: left; }
.profile-card .card-body.pre-order .btn { display: block; margin: auto; }
                        /*Pre Order::end*/

                        /*Login::Start*/
.login-modal .card-header { padding: 25px 20px 10px 20px; }
.login-modal .card-body { padding: 10px 38px 30px 38px; }
                        /*Login::End*/

                        /*Profile::Start*/
.profile-card .card-header { padding: 0; }
.profile-card .card-header .cover { width: 100%; z-index: 9; }
.profile-card .card-header .card-profile-image { z-index: 99; position: absolute; top: -60px; background-color: #fff; border-radius: 50%; display: block; margin: auto; float: none; left: 34%; }
.profile-card .card-header .card-profile-image img { width: 100%; height: auto; max-height: 120px; }
.profile-card .card-body { padding: 30px; padding-top: 70px; text-align: center; }
.profile-card .card-body span { font-size: 12px; }
.profile-card .card-body .profile-btn { display: block; margin: auto; margin-top: 5px; max-width: 150px; }
.profile-card .store-credit { font-weight: 600; color: #e3a004; }
.profile-card .store-credit img { width: 20px; }
/*.profile-card .blood-group { color: #8a0303; font-weight: 600; }*/
/*.profile-card .card-body .profile-btn:first-child { margin-top: 20px; }*/
.profile .profile-table { font-size: 13px; }
.profile .profile-table th { text-align: right; padding-top: 0; }
.profile .profile-table td { text-align: left; padding-top: 0; }
.profile .profile-table-under { width: 100%; }
.profile .profile-table-under .btn { display: block; margin: auto; }
.profile .order .order-details span { display: block; font-size: 12px; }
.profile .profile-tabs {  min-height: 350px; }
                        /*Profile::End*/

                        /*No-Data::Start*/
.no-data { width: 100%; text-align: center; font-size: 15px; }
.no-data span { display: block; }
.no-data a { display: inline-block; margin-top: 10px; }
                        /*No-Data::End*/

												/*Animation::Start*/
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
												/*Animation::End*/


                        /*Book-Animation::Start*/
.product .gallery .preview-div { position: relative; }
.product .gallery .preview-div .overlay { position: absolute; width: 100%; top: 0; left: 0; bottom: 0; background-color: rgba(0,0,0,0.5); display: none; z-index: 99; transition-timing-function: ease-in-out; transition-delay: 2s; }
.product .gallery .preview-div:hover .overlay { display: block; }
.product .gallery .overlay .btn-overlay { position: absolute; top: 45%; width: 100%; border-radius: 0; background-color: #f0b52e; border-color: #f0b52e; }
.product .gallery .btn-sample { width: 100%; border-radius: 0; background-color: #f0b52e; border-color: #f0b52e; }
                        /*Book-Animation::End*/


                        /*Sell::Start*/
.heading { text-align: center; }
.samples img { width: 100%; cursor: pointer; }
                        /*Sell::End*/

                        /*Tags input::Start*/
.ReactTags__selected { margin-top: 8px; }
.ReactTags__tagInput { margin-top: 10px; }
.tag-wrapper.ReactTags__tag { padding: 0.35rem 0.375rem; font-size: 66%; font-weight: 600; line-height: 1; text-align: center; white-space: nowrap; vertical-align: baseline; border-radius: 0.25rem; background-color: #3c4d69; color: #fff; margin-right: 2px;  text-transform: uppercase; }
.ReactTags__remove { margin-left: 5px; font-size: 10px; cursor: pointer; }
.ReactTags__tagInputField { display: block; width: 100%; height: 2rem; padding: 0.625rem 0.75rem; font-size: 0.875rem; line-height: 1.5; color: #8898aa; background-color: #fff; background-clip: padding-box; border: 1px solid #cad1d7; border-radius: 0.25rem; box-shadow: none; -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55); transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
.ReactTags__tagInputField::placeholder { color: #a5b2c0; }
.ReactTags__suggestions ul { list-style: none; margin-left: 0; background-color: #ccc; }
.ReactTags__suggestions ul li { padding: 2px 0; }
                        /*Tags input::End*/

                        /*Terms::Start*/
.terms p { font-size: 14px; }
.terms h4 { text-align: center; }
.terms h5 { text-align: center; }
.terms ul li { font-size: 14px; }
                        /*Terms::End*/