.swiper-container {
    height: 500px;
    display: inline-block;
    background: #000;
    /*this gives the dimension of slider container*/
    /*these values will make the container as big as the parent div*/
    /* overflow: hidden; */
    /*important parameter to not disrupt the container when slides increases in coverflow and other effect*/
}
.swiper-container.gallery-top { width: 80%; padding: 10px; }
.swiper-container.gallery-thumbs { width: 20%; vertical-align: top; box-sizing: border-box; padding: 10px 10px 0 0px; }

.swiper-slide {
    width:100%;
    background-size: cover;
    background-position: center;
}
.swiper-container.gallery-top .swiper-slide { height: 500px; }
.swiper-container.gallery-thumbs .swiper-slide { height: 100px !important; opacity: 0.3; cursor: pointer; }
.swiper-container.gallery-thumbs .swiper-slide-thumb-active { opacity: 1; }

